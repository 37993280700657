import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"

const Container = styled.div`
  text-align: center;
`

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Container>
        There is nothing here. <br />
        Let's go back to <Link to="/">~/</Link>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
